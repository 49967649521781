import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { SubHeader } from './ThreeRowsHeader';
import { Logo } from '../header/Logo';
import { useTheme } from '../../../../../theming/useTheme';
import { NavigationLink } from '../oldHeader/primaryHeader/PrimaryHeader';
import { Link } from '../../../../partials/link/Link';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import { MenuData } from '../header/RowWithFixedNav';
import { AdditionalStyleSheet } from '../../../../components.utils';
import { HamburgerMenu } from './HamburgerMenu';
import { ActionsBar } from './ActionsBar';
import {
  componentConfigThemeKey as threeRowsHeaderConfigThemeKey,
  ThreeRowsHeaderThemeProps,
} from './ThreeRowsHeader.theme';
import { MegaMenu } from './megaMenu';
import { isCurrentPage } from './header.util';
import { Span } from '../../../../../typography/semanticTags';
import { EditionsIconDataProps } from '../header/EditionsIcon';


interface SecondRowHeaderProps {
    subHeader: SubHeader;
    menu: MenuData | null;
    windowsDetails: { origin: string; path: string; innerWidth: number };
    showAccessibilityIcon?: boolean;
    feedName?: string;
    propertyEndpoint: string;
    siteName?: string;
    editions: NavigationLink[] | null;
    editionEndpoint: string;
    editionsIcon: EditionsIconDataProps | null;
}

const getSecondRowStyle = (
  secondLineBackgroundColor: string,
  secondLineTextColor: string,
  secondRowHeightInLargeScreen: number,
  secondRowHeightInMediumScreen: number,
  secondRowHeightInSmallScreen: number,
) => StyleSheet.create({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 0 0 16px',
    gap: '16px',
    [MEDIA_BREAKPOINTS.large]: {
      height: `${secondRowHeightInLargeScreen}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      height: `${secondRowHeightInMediumScreen}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      height: `${secondRowHeightInSmallScreen}px`,
    },
    textTransform: 'uppercase',
    backgroundColor: secondLineBackgroundColor,
    boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.20)',
  },
  logoAndHamburgerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  linksList: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    columnGap: '16px',
    listStyle: 'none',
    whiteSpace: 'nowrap',
    overflowX: 'scroll',
    overflowY: 'hidden',
    [MEDIA_BREAKPOINTS.medium]: {
      display: 'none',
    },
    '::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
  },
  link: {
    color: secondLineTextColor,
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    alignItems: 'center',
    textDecoration: 'none',
    [MEDIA_BREAKPOINTS.large]: {
      height: `${secondRowHeightInLargeScreen}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      height: `${secondRowHeightInMediumScreen}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      height: `${secondRowHeightInSmallScreen}px`,
    },
    ':hover + span': {
      display: 'block',
    },
  },
});

const getIndicatorDisplayStyle = (navigationLink: NavigationLink, secondLineSelectedIndicatorColor: string, isCurrent: boolean) => {
  return StyleSheet.create({
    indicator: {
      height: '3px',
      backgroundColor: secondLineSelectedIndicatorColor,
      width: '100%',
      bottom: '0',
      position: 'absolute',
      display: isCurrent ? 'block' : 'none',
    },
  }).indicator;
};

const createMenuStyle = (): AdditionalStyleSheet => {
  return StyleSheet.create({
    style: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'none',
      },
    },
  }) as AdditionalStyleSheet;
};

const createImageAddtionalStyle = StyleSheet.create({
  style: {
    maxWidth: '240px',
  },
}) as AdditionalStyleSheet;

export const SecondRowHeader: React.FunctionComponent<SecondRowHeaderProps> = props => {
  const {
    subHeader,
    menu,
    showAccessibilityIcon,
    feedName,
    windowsDetails,
    propertyEndpoint,
    siteName,
    editions,
    editionEndpoint,
    editionsIcon,
  } = props;
  const { logoForLargeScreen, links, megaMenu } = subHeader;
  const {
    secondLineBackgroundColor,
    secondLineTextColor,
    secondLineSelectedIndicatorColor,
    secondRowHeightInLargeScreen,
    secondRowHeightInMediumScreen,
    secondRowHeightInSmallScreen,
    secondRowLinkFontSizeLarge,
    secondRowLinkFontSizeMedium,
    secondRowLinkFontSizeSmall,
  } = useTheme<ThreeRowsHeaderThemeProps>(threeRowsHeaderConfigThemeKey);
  const style = getSecondRowStyle(
    secondLineBackgroundColor,
    secondLineTextColor,
    secondRowHeightInLargeScreen,
    secondRowHeightInMediumScreen,
    secondRowHeightInSmallScreen,
  );

  return (
    <div className={css(style.wrapper)}>
      <div className={css(style.logoAndHamburgerContainer)}>
        {menu && (
          <HamburgerMenu
            iconColor={secondLineTextColor}
            links={menu.links}
            additionalStyle={createMenuStyle()}
          />
        )}
        {logoForLargeScreen && (
        <Logo
          {...logoForLargeScreen}
          logoHeightInLargeScreen={1.8}
          logoHeightInMediumScreen={1.8}
          logoHeightInSmallScreen={1.8}
          imageAdditionalStyles={createImageAddtionalStyle}
        />
        )}
      </div>
      {links && (
        <ul className={css(style.linksList)}>
          {links.map((navigationLink: NavigationLink, index: number) => {
            const indicatorStyle = getIndicatorDisplayStyle(navigationLink, secondLineSelectedIndicatorColor, isCurrentPage(navigationLink, windowsDetails, feedName));
            return (
              <li key={index} className={css(style.link)}>
                <Link className={css(style.link)} href={navigationLink.link.href}>
                  <Span
                    fontSizeLarge={secondRowLinkFontSizeLarge}
                    fontSizeMedium={secondRowLinkFontSizeMedium}
                    fontSizeSmall={secondRowLinkFontSizeSmall}
                  >
                    {navigationLink.link.text}
                  </Span>
                </Link>
                <span className={css(indicatorStyle)} />
              </li>
            );
          })}
          {megaMenu
                        && <MegaMenu moreIconColor={secondLineTextColor} megaMenu={megaMenu} siteName={siteName} />}
        </ul>
      )}
      <ActionsBar
        iconColor={secondLineTextColor}
        showAccessibilityIcon={showAccessibilityIcon}
        propertyEndpoint={propertyEndpoint}
        editions={editions}
        editionEndpoint={editionEndpoint}
        editionsIcon={editionsIcon}
      />
    </div>
  );
};
