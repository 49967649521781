import React, { useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { ErrorBoundary } from '../../../../errorHandling/ErrorBoundary';
import { LogoDataProps } from '../header/Logo';
import { NavigationLink } from '../oldHeader/primaryHeader/PrimaryHeader';
import { MenuData } from '../header/RowWithFixedNav';
import { FirstRowHeader } from './FirstRowHeader';
import { SecondRowHeader } from './SecondRowHeader';
import { ThirdRowHeader } from './ThirdRowHeader';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import { EditionsIconDataProps } from '../header/EditionsIcon';

export interface SubHeader {
    logoForLargeScreen: LogoDataProps | null;
    links: NavigationLink[] | null;
    megaMenu: NavigationLink[] | null;
}

export interface ThirdHeader {
    links: NavigationLink[];
    feedName: string;
}

export interface Subscribe {
    linkText: string;
    url: string;
}

export interface ThreeRowsHeaderProps {
    links: NavigationLink[];
    menu: MenuData | null;
    subHeader: SubHeader | null;
    showAccessibilityIcon?: boolean;
    subscribeLink?: Subscribe;
    thirdHeader: ThirdHeader | null;
    propertyEndpoint: string;
    siteName?: string;
    editions: NavigationLink[] | null;
    editionEndpoint: string;
    editionsIcon: EditionsIconDataProps | null;
}

const getHeaderStyles = () => {
  return StyleSheet.create({
    style: {
      width: '100%',
      position: 'sticky',
      zIndex: 1500,
      top: 0,
      [MEDIA_BREAKPOINTS.small]: {
        position: 'relative',
      },
    },
  });
};

export const ThreeRowsHeader: React.FunctionComponent<ThreeRowsHeaderProps> = props => {
  const [windowsDetails, setWindowsDetails] = useState({ origin: '', path: '', innerWidth: 0 });
  const {
    links,
    subscribeLink,
    subHeader,
    menu,
    showAccessibilityIcon,
    thirdHeader,
    propertyEndpoint,
    siteName,
    editions,
    editionEndpoint,
    editionsIcon,
  } = props;
  const headerStyle = getHeaderStyles();

  React.useEffect(() => {
    if (window) {
      setWindowsDetails({
        origin: window.location.origin,
        path: window.location.pathname,
        innerWidth: window.innerWidth,
      });
    }
  }, []);

  return (
    <ErrorBoundary>
      <header className={css(headerStyle.style)}>
        {links && <FirstRowHeader links={links} subscribeLink={subscribeLink} />}
        {subHeader?.links
                    && (
                    <SecondRowHeader
                      subHeader={subHeader}
                      menu={menu}
                      showAccessibilityIcon={showAccessibilityIcon}
                      feedName={thirdHeader?.feedName}
                      windowsDetails={windowsDetails}
                      propertyEndpoint={propertyEndpoint}
                      siteName={siteName}
                      editions={editions}
                      editionEndpoint={editionEndpoint}
                      editionsIcon={editionsIcon}
                    />
)}
        {thirdHeader?.links && <ThirdRowHeader thirdHeader={thirdHeader} windowsDetails={windowsDetails} />}
      </header>

    </ErrorBoundary>
  );
};
